import { Link, useNavigate, useParams } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  PageWrapper,
  Progress,
  Spinner,
  toast,
} from '@agyt/client/shared/ui/components';
import { IconCheckCircle } from '@agyt/client/shared/ui/icons';
import {
  BeneficiaryRequirements,
  useBeneficiaryRequirements,
  useGetBeneficiaryAccounts,
  useUpdateBeneficiary,
  useValidateBeneficiary,
} from '@agyt/client/web/data-access/api';
import { ApiFieldError, BeneficiaryResponse } from '@agyt/shared/types';
import { SupportedCurrency } from '@agyt/shared/util/common';
import AddBeneficiaryReview from './add-beneficiary-review';
import BankAccountDetailsForm, {
  accountDetailsKeyMap,
} from './bank-account-details-form';
import { editBeneficiaryRoute } from './beneficiaries-page';
import BeneficiaryDetailsForm from './beneficiary-details-form';

const enum EditBeneficiaryStep {
  'BENEFICIARY_DETAILS',
  'BANK_ACCOUNT_DETAILS',
  'REVIEW',
  'SUCCESS',
}

function AccountDetailsSection(props: {
  formData: any;
  isLoading: boolean;
  beneficiaryDetails?: BeneficiaryDetailsForm;
  beneficiaryRequirements?: BeneficiaryRequirements[];
  onBankAccountDetailsSubmit: (data: any) => void;
  onBankAccountDetailsBack: () => void;
  submit: (data: any) => void;
  errors?: ApiFieldError[];
  isValidating?: boolean;
}) {
  const { t } = useTranslation('beneficiaries:new');

  if (
    props.isLoading ||
    !props.beneficiaryDetails ||
    !props.beneficiaryRequirements
  ) {
    return <Spinner />;
  }

  const filteredRequirements = props.beneficiaryRequirements?.filter(
    (r: BeneficiaryRequirements) =>
      r.beneficiary_entity_type === props.beneficiaryDetails?.type,
  );

  return (
    <section>
      <h2 className="text-3xl font-medium text-slate-900">
        {t('bankAccountDetails.title')}
      </h2>
      <BankAccountDetailsForm
        formData={props.formData}
        beneficiaryRequirements={filteredRequirements}
        submit={props.onBankAccountDetailsSubmit}
        back={props.onBankAccountDetailsBack}
        errors={props.errors}
        isValidating={props.isValidating}
      />
    </section>
  );
}

export function EditBeneficiaryPage() {
  const { t } = useTranslation('beneficiaries:new');
  const navigate = useNavigate({ from: '/beneficiaries' });
  const searchParams = editBeneficiaryRoute.useSearch();

  const [step, setStep] = useState<EditBeneficiaryStep>(
    EditBeneficiaryStep.BENEFICIARY_DETAILS,
  );
  const [beneficiaryDetails, setBeneficiaryDetails] = useState<
    BeneficiaryDetailsForm | undefined
  >();
  const [accountDetails, setAccountDetails] = useState<any>([]);
  const [validateBeneficiaryErrors, setValidateBeneficiaryErrors] = useState<
    ApiFieldError[]
  >([]);
  const [validatedBeneficiary, setValidatedBeneficiary] =
    useState<BeneficiaryResponse>();
  const [progress, setProgress] = useState<number>(33);
  const [redirectProgress, setRedirectProgress] = useState<number>(0);

  const { id } = useParams({ strict: false });
  const {
    data: accountsData,
    isError,
    isFetching,
  } = useGetBeneficiaryAccounts(id);

  const {
    data: beneficiaryRequirementsData,
    isLoading: isBeneficiaryRequirementsLoading,
  } = useBeneficiaryRequirements({
    currency: beneficiaryDetails?.currency as SupportedCurrency,
    beneficiaryCountry: beneficiaryDetails?.country ?? '',
    bankAccountCountry: beneficiaryDetails?.bankCountry ?? '',
  });
  const validateBeneficiary = useValidateBeneficiary();
  const editBeneficiary = useUpdateBeneficiary();

  useEffect(() => {
    if (accountsData && accountsData.data && accountsData.data.length) {
      const beneficiary = {
        type: accountsData.data[0].beneficiaryType as any,
        name: accountsData.data[0].name,
        country: accountsData.data[0].beneficiaryCountry,
        bankCountry: accountsData.data[0].bankCountry,
        currency: accountsData.data[0].currency,
        bankAccountHolderName: accountsData.data[0].bankAccountHolderName,
      };
      setBeneficiaryDetails(beneficiary);

      const beneficiaryAccountDetails = Object.keys(
        accountDetailsKeyMap,
      ).reduce((curr: any, key) => {
        const value = (accountsData.data[0] as any)[key];
        if (value) {
          const ccKey = (accountDetailsKeyMap as any)[key];
          if (ccKey === 'beneficiary_address') {
            curr[ccKey] = value[0]; // because CC is CC
          } else {
            curr[ccKey] = value;
          }
        }
        return curr;
      }, {});

      setAccountDetails(beneficiaryAccountDetails);
    }
  }, [accountsData]);

  useEffect(() => {
    if (step === EditBeneficiaryStep.SUCCESS) {
      const interval = setInterval(() => {
        setRedirectProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            const returnTo = searchParams.returnTo ?? '/beneficiaries';
            navigate({
              to: returnTo,
            });
            return 100;
          }
          return prevProgress + 1;
        });
      }, 50);

      return () => clearInterval(interval);
    }
  }, [step, navigate, searchParams]);

  async function onBeneficiaryDetailsSubmit(data: any) {
    setBeneficiaryDetails(data);
    setStep(EditBeneficiaryStep.BANK_ACCOUNT_DETAILS);
    setProgress(66);
  }

  async function onBankAccountDetailsSubmit(data: any) {
    setAccountDetails(data);

    try {
      const validatedBeneficiary = await validateBeneficiary.mutateAsync({
        beneficiaryDetails,
        accountDetails: data,
      });
      setValidateBeneficiaryErrors([]);
      setValidatedBeneficiary(validatedBeneficiary.data);
      setStep(EditBeneficiaryStep.REVIEW);
      setProgress(100);
    } catch (error) {
      const errors = (error as any).response.data.errors;
      setValidateBeneficiaryErrors(errors);
    }
  }

  async function onBeneficiaryReviewSubmit() {
    try {
      await editBeneficiary.mutateAsync({
        id,
        payload: {
          beneficiaryDetails,
          accountDetails,
        },
      });
      setStep(EditBeneficiaryStep.SUCCESS);
    } catch (error) {
      toast.error(t('errors.update_failed'));
    }
  }

  function onBankAccountDetailsBack() {
    setStep(EditBeneficiaryStep.BENEFICIARY_DETAILS);
    setProgress(33);
  }

  function onBeneficiaryReviewBack() {
    setStep(EditBeneficiaryStep.BANK_ACCOUNT_DETAILS);
    setProgress(66);
  }

  function renderStep() {
    switch (step) {
      case EditBeneficiaryStep.BANK_ACCOUNT_DETAILS:
        return (
          <AccountDetailsSection
            formData={accountDetails}
            isLoading={isBeneficiaryRequirementsLoading}
            beneficiaryDetails={beneficiaryDetails}
            beneficiaryRequirements={beneficiaryRequirementsData?.data}
            onBankAccountDetailsSubmit={onBankAccountDetailsSubmit}
            onBankAccountDetailsBack={onBankAccountDetailsBack}
            submit={onBankAccountDetailsSubmit}
            errors={validateBeneficiaryErrors}
            isValidating={validateBeneficiary.isPending}
          />
        );
      case EditBeneficiaryStep.REVIEW:
        return (
          <section>
            <h2 className="text-3xl font-medium text-slate-900">
              {t('beneficiaryDetails.title')}
            </h2>
            {validatedBeneficiary && (
              <AddBeneficiaryReview
                beneficiary={{
                  ...validatedBeneficiary,
                  name: beneficiaryDetails?.name ?? '',
                  bankAccountHolderName:
                    beneficiaryDetails?.bankAccountHolderName ?? '',
                }}
                back={onBeneficiaryReviewBack}
                submit={onBeneficiaryReviewSubmit}
                isSubmitting={editBeneficiary.isPending}
                isEdit={true}
              />
            )}
          </section>
        );
      case EditBeneficiaryStep.SUCCESS:
        return (
          <section className="flex flex-col items-center">
            <IconCheckCircle />
            <h2 className="mt-8 text-xl font-medium text-slate-900">
              {t('success.title', { ns: 'beneficiaries:edit' })}
            </h2>
            <div className="mt-7 text-base leading-7 text-slate-500">
              <span className="font-semibold">{beneficiaryDetails?.name}</span>
              &nbsp;
              <span>{t('success.subtitle', { ns: 'beneficiaries:edit' })}</span>
            </div>
            <Link
              to={
                searchParams.returnTo ? searchParams.returnTo : '/beneficiaries'
              }
            >
              <Button className="mt-9">
                {t('actions.finish', { ns: 'common' })}
              </Button>
            </Link>
            <Progress value={redirectProgress} className="mt-6 h-1 w-24" />
          </section>
        );
      case EditBeneficiaryStep.BENEFICIARY_DETAILS:
      default:
        return (
          <section>
            <h2 className="text-3xl font-medium text-slate-900">
              {t('beneficiaryDetails.title')}
            </h2>
            <BeneficiaryDetailsForm
              submit={onBeneficiaryDetailsSubmit}
              formData={beneficiaryDetails}
              editMode={true}
            />
          </section>
        );
    }
  }

  return (
    <PageWrapper className="flex flex-col items-center">
      <div className="flex w-[555px] flex-col">
        {isFetching && (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )}

        {!isFetching && (
          <>
            {step !== EditBeneficiaryStep.SUCCESS && (
              <div className="relative w-full">
                <Progress value={progress} />
              </div>
            )}
            <div className="mt-14 w-full">{renderStep()}</div>
          </>
        )}
      </div>
    </PageWrapper>
  );
}
