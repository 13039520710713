import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DetailsCard,
  LoadingButton,
} from '@agyt/client/shared/ui/components';
import { BeneficiaryResponse } from '@agyt/shared/types';
import { getCountryNameFromCode } from '@agyt/shared/util/common';

const beneficiaryDetailsOrderMap = {
  name: 0,
  bankAccountHolderName: 1,
  bankCountry: 2,
  beneficiaryType: 3,
};

const accountDetailsOrderMap = {
  iban: 0,
  accountNumber: 1,
  bic: 2,
  sortCode: 3,
  aba: 4,
  bsbCode: 5,
  institutionNumber: 6,
  bankCode: 7,
  branchCode: 8,
  clabe: 9,
  cnaps: 10,
  paymentTypes: 11,
  beneficiaryAddress: 12,
  beneficiaryCity: 13,
  beneficiaryCountry: 14,
};

// Currency cloud does not take or return name and bankAccountHolderName on validate even though it is required
// for the create endpoint. In order to show these on the review page, we need to append it to the returned validated data.
interface BeneficiaryReviewDetails extends BeneficiaryResponse {
  name: string;
  bankAccountHolderName: string;
}

export interface AddBeneficiaryReviewProps {
  beneficiary: BeneficiaryReviewDetails;
  submit: () => void;
  back: () => void;
  isSubmitting?: boolean;
  isEdit?: boolean;
}

export function AddBeneficiaryReview({
  beneficiary,
  submit,
  back,
  isSubmitting,
  isEdit, // temp hack for delivery sake
}: AddBeneficiaryReviewProps) {
  const { t } = useTranslation('beneficiaries:details');

  const beneficiaryDetails = useMemo(() => {
    if (!beneficiary) {
      return null;
    }

    return Object.keys(beneficiaryDetailsOrderMap).map((key, value) => {
      if (key === 'beneficiaryType') {
        return {
          key: t(`labels.${key}`),
          value: t(`values.${beneficiary[key]}`),
        };
      }

      return {
        key: t(`labels.${key}`),
        value: (beneficiary as any)[key],
      };
    });
  }, [beneficiary, t]);

  const bankAccountDetails = useMemo(() => {
    if (!beneficiary) {
      return null;
    }

    return Object.keys(accountDetailsOrderMap)
      .map((key, value) => {
        if (key === 'paymentTypes') {
          const types = beneficiary[key];
          const value = types
            .map((type: string) => t(`values.${type}`))
            .join(', ');
          return {
            key: t(`labels.${key}`),
            value,
          };
        }

        if (key === 'beneficiaryAddress') {
          return {
            key: t(`labels.${key}`),
            value: `${(beneficiary[key] as unknown as string[]).join(',')}`, // because CC returns a string[] even though a string is sent as input
          };
        }

        if (key === 'beneficiaryCountry') {
          return {
            key: t(`labels.${key}`),
            value: getCountryNameFromCode(beneficiary[key]),
          };
        }

        return {
          key: t(`labels.${key}`),
          value: (beneficiary as any)[key],
        };
      })
      .filter((o) => o.value);
  }, [beneficiary, t]);
  return (
    <>
      <div>
        <h3 className="mt-16 text-xl font-medium text-slate-900">
          {t('beneficiaryDetails.title')}
        </h3>
        {beneficiaryDetails && <DetailsCard data={beneficiaryDetails} />}
      </div>
      <div>
        <h3 className="mt-10 text-xl font-medium text-slate-900">
          {t('bankAccountDetails.title')}
        </h3>

        {bankAccountDetails && <DetailsCard data={bankAccountDetails} />}
      </div>
      <div className="mt-16 flex gap-4">
        <Button variant="subtleDark" className="flex-1" onClick={back}>
          {t('actions.back', { ns: 'common' })}
        </Button>
        <LoadingButton
          className="flex-1"
          onClick={submit}
          isLoading={!!isSubmitting}
        >
          {isEdit
            ? t('actions.update', { ns: 'common' })
            : t('actions.create', { ns: 'common' })}
        </LoadingButton>
      </div>
    </>
  );
}

export default AddBeneficiaryReview;
