import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  Separator,
  CurrencyPicker,
  CountryPicker,
} from '@agyt/client/shared/ui/components';
import { countries } from '@agyt/shared/util/common';

export const beneficiaryTypes = ['company', 'individual'] as const;

const beneficiaryTypeEnum = z.enum(beneficiaryTypes, {
  message: 'common.validation.required',
});

const beneficiaryDetailsForm = z.object({
  type: beneficiaryTypeEnum,
  name: z.string().min(1, {
    message: 'required',
  }),
  country: z.string().min(1, {
    message: 'required',
  }),
  bankCountry: z.string().min(1, {
    message: 'required',
  }),
  currency: z.string().min(1, {
    message: 'required',
  }),
  bankAccountHolderName: z.string().min(1, {
    message: 'required',
  }),
});

export type BeneficiaryDetailsForm = z.infer<typeof beneficiaryDetailsForm>;

export interface BeneficiaryDetailsFormProps {
  submit: (data: BeneficiaryDetailsForm) => void;
  formData?: BeneficiaryDetailsForm;
  editMode?: boolean;
}

export function BeneficiaryDetailsForm(props: BeneficiaryDetailsFormProps) {
  const { t } = useTranslation('beneficiaries:new');
  const { formData } = props;

  const form = useForm<BeneficiaryDetailsForm>({
    resolver: zodResolver(beneficiaryDetailsForm),
    defaultValues: {
      type: formData?.type || beneficiaryTypeEnum.enum.company,
      name: formData?.name || '',
      country: formData?.country || '',
      bankCountry: formData?.bankCountry || '',
      currency: formData?.currency || '',
      bankAccountHolderName: formData?.bankAccountHolderName || '',
    },
  });

  useEffect(() => {
    if (formData) {
      form.reset(formData);
    }
  }, [formData, form]);

  function onSubmit(data: BeneficiaryDetailsForm) {
    props.submit(data);
  }

  return (
    <Form {...form}>
      <form className="mt-16" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                {t('beneficiaryDetails.beneficiaryType')}
              </FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex gap-4"
                  disabled={props.editMode}
                  {...field}
                >
                  <FormItem className="flex-1">
                    <FormLabel className="item-center flex h-10 flex-1 rounded-md border border-slate-200 bg-white p-3 text-slate-900 hover:cursor-pointer">
                      <FormControl>
                        <RadioGroupItem value="company"></RadioGroupItem>
                      </FormControl>
                      <span className="ml-2">
                        {t('beneficiaryDetails.company')}
                      </span>
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex-1">
                    <FormLabel className="item-center flex h-10 flex-1 rounded-md border border-slate-200 bg-white p-3 text-slate-900 hover:cursor-pointer">
                      <FormControl>
                        <RadioGroupItem value="individual"></RadioGroupItem>
                      </FormControl>
                      <span className="ml-2">
                        {t('beneficiaryDetails.individual')}
                      </span>
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Separator className="my-10" />

        <section>
          <h3 className="mb-6 text-xl font-medium text-slate-900">
            {t('beneficiaryDetails.beneficiaryInformation')}
          </h3>

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.name')}
                </FormLabel>
                <FormControl>
                  <Input {...field}></Input>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.country')}
                </FormLabel>
                <FormControl>
                  <CountryPicker
                    value={countries.find(
                      (country) => country.value === field.value,
                    )}
                    onChange={(country) => field.onChange(country?.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </section>

        <Separator className="my-10" />

        <section>
          <h3 className="mb-6 text-xl font-medium text-slate-900">
            {t('beneficiaryDetails.bankAccountInformation')}
          </h3>

          <FormField
            control={form.control}
            name="bankCountry"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.bank_country')}
                </FormLabel>
                <FormControl>
                  <CountryPicker
                    value={countries.find(
                      (country) => country.value === field.value,
                    )}
                    onChange={(country) => field.onChange(country?.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="currency"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.currency')}
                </FormLabel>
                <FormControl>
                  <CurrencyPicker
                    value={field.value}
                    onChange={(currency) => field.onChange(currency?.value)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="bankAccountHolderName"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="mb-2 text-sm font-medium text-slate-900">
                  {t('beneficiaryDetails.labels.bank_account_holder_name')}
                </FormLabel>
                <FormControl>
                  <Input {...field}></Input>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </section>

        <div className="mt-16 flex gap-4">
          <Button type="submit" className="flex-1">
            {t('actions.next', { ns: 'common' })}
          </Button>
        </div>
      </form>
    </Form>
  );
}

export default BeneficiaryDetailsForm;
