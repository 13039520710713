import { Link, useNavigate, useParams } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  DetailsCard,
  PageWrapper,
  Skeleton,
  toast,
} from '@agyt/client/shared/ui/components';
import { IconArrowUpRight } from '@agyt/client/shared/ui/icons';
import {
  useDeleteBeneficiary,
  useGetBeneficiaryAccounts,
} from '@agyt/client/web/data-access/api';
import { getCountryNameFromCode } from '@agyt/shared/util/common';
import DeleteBeneficiaryDialog from './delete-beneficiary-dialog';

const beneficiaryDetailsOrderMap = {
  name: 0,
  beneficiaryCountry: 1,
  bankAccountHolderName: 2,
  beneficiaryType: 3,
};

const accountDetailsOrderMap = {
  iban: 0,
  accountNumber: 1,
  bic: 2,
  sortCode: 3,
  aba: 4,
  bsbCode: 5,
  institutionNumber: 6,
  bankCode: 7,
  branchCode: 8,
  clabe: 9,
  cnaps: 10,
  paymentTypes: 11,
  beneficiaryAddress: 12,
  beneficiaryCity: 13,
  beneficiaryCountry: 14,
};

export function BeneficiaryDetailsPage() {
  const navigate = useNavigate({ from: '/beneficiaries/$id' });
  const { id } = useParams({ strict: false });
  const {
    data: accountsData,
    isError,
    isFetching,
  } = useGetBeneficiaryAccounts(id);
  const { t } = useTranslation('beneficiaries:details');
  const deleteBeneficiary = useDeleteBeneficiary();

  const accountDetails = useMemo(() => {
    if (!accountsData?.data?.length) {
      return null;
    }

    return accountsData.data[0];
  }, [accountsData]);

  const beneficiaryDetails = useMemo(() => {
    if (!accountDetails) {
      return null;
    }

    return Object.keys(beneficiaryDetailsOrderMap).map((key, value) => {
      if (key === 'beneficiaryType') {
        return {
          key: t(`labels.${key}`),
          value: t(`values.${accountDetails[key]}`),
        };
      }

      if (key === 'beneficiaryCountry') {
        return {
          key: t(`labels.${key}`),
          value: getCountryNameFromCode(accountDetails[key]),
        };
      }

      return {
        key: t(`labels.${key}`),
        value: (accountDetails as any)[key],
      };
    });
  }, [accountDetails, t]);

  const bankAccountDetails = useMemo(() => {
    if (!accountDetails) {
      return null;
    }

    return Object.keys(accountDetailsOrderMap)
      .map((key, value) => {
        if (key === 'paymentTypes') {
          const types = accountDetails[key];
          const value = types
            .map((type: string) => t(`values.${type}`))
            .join(', ');
          return {
            key: t(`labels.${key}`),
            value,
          };
        }

        if (key === 'beneficiaryAddress') {
          return {
            key: t(`labels.${key}`),
            value: `${(accountDetails as any)[key].join(',')}`,
          };
        }

        if (key === 'beneficiaryCountry') {
          return {
            key: t(`labels.${key}`),
            value: getCountryNameFromCode(accountDetails[key]),
          };
        }

        return {
          key: t(`labels.${key}`),
          value: (accountDetails as any)[key],
        };
      })
      .filter((o) => o.value);
  }, [accountDetails, t]);

  function onDeleteBeneficiary() {
    return async () => {
      try {
        await deleteBeneficiary.mutateAsync({ id });
      } catch (err: any) {
        toast.error(t('errors.delete_failed'));
      }
    };
  }

  if (isFetching) {
    return (
      <PageWrapper className="flex flex-col gap-8">
        <Skeleton className="h-8 w-40 rounded-full" />
        <Skeleton className="h-8 w-80 rounded-full" />

        <Skeleton className="mt-8 h-8 w-80 rounded-full" />
        <div className="flex flex-col gap-4">
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
          <Skeleton className="h-8 w-1/2 rounded-full" />
        </div>
      </PageWrapper>
    );
  }

  if (!accountDetails && !isError && !isFetching) {
    return (
      <PageWrapper className="flex flex-col">No account data.</PageWrapper>
    );
  }

  return (
    <PageWrapper className="flex flex-col">
      <Breadcrumb className="mb-10">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage
              onClick={() => navigate({ to: '/beneficiaries' })}
              className="cursor-pointer"
            >
              {t('title', { ns: 'beneficiaries' })}
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{accountDetails?.name}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <header className="mb-16 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">{accountDetails?.name}</h1>
        </div>

        <div className="flex items-center gap-3">
          <Link to="/pay" search={{ beneficiaryId: id }}>
            <Button variant="default">
              {t('actions.pay', { ns: 'common' })}
            </Button>
          </Link>
          <Link to={`edit`}>
            <Button variant="subtleDark">
              {t('actions.edit', { ns: 'common' })}
            </Button>
          </Link>
          <DeleteBeneficiaryDialog onDelete={onDeleteBeneficiary()} />
        </div>
      </header>

      <section className="mb-10 w-1/2">
        <h3 className="mb-4 text-xl font-medium">
          {t('beneficiaryDetails.title')}
        </h3>

        {beneficiaryDetails && <DetailsCard data={beneficiaryDetails} />}
      </section>

      <section className="w-1/2">
        <h3 className="mb-4 text-xl font-medium">
          {t('bankAccountDetails.title')}
        </h3>

        {bankAccountDetails && <DetailsCard data={bankAccountDetails} />}
      </section>
    </PageWrapper>
  );
}

export default BeneficiaryDetailsPage;
